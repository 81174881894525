import request from '@/utils/request'

export function swiperapi(data) {
  return request({
    url: '/v2/wsyg/adPosition',
    method: 'POST',
    data: data
  })
}

export function qsapi(data) {
  return request({
    url: '/v2/wsyg/problem',
    method: 'POST',
    data: data
  })
}