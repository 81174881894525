<template>
<!--  常见问题-->
  <div class=" servewrap divMa bjt5 bjtcssMping">

    <titleDiv title="常见问题" english-title="COMMON PROBLEM"></titleDiv>

    <el-collapse v-model="collapseconfig.activename" @change="change">
      <el-collapse-item v-for="(item,index) in collapseconfig.data"
                        :key='index' :title="item.problemName" :name="item.craetdOn||index">
        <div v-html="item.problemContent" class="collapse_content"></div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import '@/style/css/index.css';
import {qsapi} from "@/api/question";
import titleDiv from "@/components/title.vue";
export default {
  components:{
    titleDiv
  },
  name: "commonProblem",
  data() {
    return {
      project:this.$store.state.project,
      collapseconfig: {
        activename: [],
        data: []
      },
    }
  },
  methods:{
    change(){

    },
  },
  props:["bjImgHidden"],
  beforeMount(){
    let key='commonProblem';
    console.log(key)
    //this.projeSid = this.$store.state.project.projectid;//项目id
    //sessionStorage.removeItem(key);//清空session
    const singlePageQ =window.sessionStorage.getItem(key)

    if(!(this.project.enableSession &&!!singlePageQ)){
      //判断缓存,不存在
      console.log("请求接口"+key)

      qsapi({apartmentSid : this.project.projectid}).then((res) => {
        this.collapseconfig.data = res.data.result;
        window.sessionStorage.setItem(key, JSON.stringify(this.collapseconfig.data))
      })

    }else {
      console.log("读缓存")
      this.collapseconfig.data = JSON.parse(window.sessionStorage.getItem(key))
    }
  },
}
</script>

<style scoped>
.servewrap{
  font-size: 12px;
}

/* 第一块内容 */
.collapse_content{
  line-height: 26px;
  letter-spacing: 1px;
}

>>>.el-collapse-item__header{
  font-weight: 600;
}

>>>.el-collapse{
  border-top: none;
}

>>>.el-collapse-item__header,>>>.el-collapse-item__wrap{
  background-color: transparent;
}
.el-collapse{
  /*padding: 5px;*/
}
@media (max-width: 600px){

}
</style>
