
<template>
  <div class="servewrap">
    <!-- 顶部 -->
    <headbox></headbox>
    <!-- banner -->
    <banner positionSid="3860"></banner>
    <!-- 常见问题 -->
    <commonProblem></commonProblem>
    <!-- 侧边导航 -->
    <sidebar ref="mysiderbar"></sidebar>
    <!-- 底部 -->
    <foot></foot>
  </div>
</template>

<script>
import 'swiper/swiper-bundle.css'
import headbox from "@/components/head";
import banner from "@/components/banner"
import commonProblem from "@/components/commonProblem"
import foot from '@/components/foot'
import sidebar from '@/components/sidebar'

export default {
  components:{
    headbox ,
    banner,
    commonProblem,
    foot ,
    sidebar ,
  },
  data() {
    return {
    }
  },
  methods:{
  },
  mounted(){
  },
  beforeDestroy() {
    /* 组件销毁的时候要移除侦听器 */
    // window.removeEventListener('click', this.hideChat)
  },
}
</script>
<style scoped>
.servewrap {
  font-size: 12px;
}
</style>
